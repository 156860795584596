"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DomainService = exports.domainLocales = exports.domains = exports.countries = void 0;
// TODO: move country codes from country-codes.ts to shared to avoid DRY/duplication
exports.countries = [
    'GB',
    'US',
    'DE',
    'FR',
    'AT',
    'SG',
    'IN',
    'CA',
    'IT',
    'KR',
    'CH',
    'PL',
    'DK',
    'CN',
    'VN',
    'TH',
    'JP',
    'TW',
    'ID',
    'MY',
    'ES',
    'ZA',
    'TR',
    'BR',
    'EE',
    'MX',
    'BE',
    'NL',
    'PT',
    'SE',
    'FI',
    'CZ',
    'BA',
    'HR',
    'LV',
    'NO',
    'RO',
    'RS',
    'SK',
    'SI',
    'UA',
    'AU',
    'NZ',
    'BG'
];
function generateCountryDomains(root) {
    let object = {};
    exports.countries.map((country) => {
        object[country] = `${country.toLowerCase()}.${root}`;
    });
    return object;
}
const publicUrl = process.env.LCA_PUBLIC_URL?.split('//')[1]; // yikes
// TODO: perf: generate domain:country key value map so we don't have to use Object.keys/find
exports.domains = {
    // only prod domains are listed manually
    production: {
        DE: 'rbtx.de',
        GB: 'rbtx.co.uk',
        US: 'rbtx.com',
        FR: 'rbtx.fr',
        IT: 'rbtx.it',
        CH: 'rbtx.ch',
        AT: 'rbtx.at',
        DK: 'rbtx.dk',
        EE: 'rbtx.ee',
        BE: 'rbtx.be',
        NL: 'rbtx.nl',
        PL: 'rbtx.pl',
        PT: 'rbtx.pt',
        SE: 'rbtx.se',
        ES: 'rbtx.es',
        ID: 'rbtx.co.id',
        IN: 'rbtx.in',
        MY: 'rbtx.my',
        SG: 'rbtx.sg',
        KR: 'rbtx.co.kr',
        TW: 'rbtx.tw',
        MX: 'mx.rbtx.shop',
        CA: 'ca.rbtx.shop',
        TH: 'th.rbtx.shop',
        CN: 'rbtx.igus.cn',
        VN: 'rbtx.vn',
        ZA: 'za.rbtx.shop',
        BR: 'br.rbtx.shop',
        JP: 'jp.rbtx.shop',
        TR: 'tr.rbtx.shop',
        CZ: 'rbtx.cz',
        AU: 'au.rbtx.shop',
        BA: 'ba.rbtx.shop',
        BG: 'bg.rbtx.shop',
        HR: 'hr.rbtx.shop',
        FI: 'fi.rbtx.shop',
        LV: 'lv.rbtx.shop',
        NZ: 'nz.rbtx.shop',
        NO: 'no.rbtx.shop',
        RO: 'ro.rbtx.shop',
        RS: 'rs.rbtx.shop',
        SK: 'sk.rbtx.shop',
        SI: 'si.rbtx.shop',
        UA: 'ua.rbtx.shop'
    },
    staging: { ...generateCountryDomains(publicUrl), US: publicUrl },
    development: { ...generateCountryDomains(publicUrl), US: publicUrl }
};
exports.domainLocales = {
    DE: ['de-DE', 'en-GB'],
    GB: ['en-GB'],
    US: ['en-US'],
    FR: ['fr-FR', 'en-GB'],
    IT: ['it-IT', 'en-GB'],
    CH: ['de-CH', 'it-CH', 'fr-CH', 'en-GB'],
    AT: ['de-AT', 'en-GB'],
    DK: ['da-DK', 'en-GB'],
    EE: ['et-EE', 'en-GB'],
    BE: ['fr-BE', 'nl-BE', 'en-GB'],
    NL: ['nl-NL', 'en-GB'],
    PL: ['pl-PL', 'en-GB'],
    PT: ['pt-PT', 'en-GB'],
    SE: ['sv-SE', 'en-GB'],
    ES: ['es-ES', 'en-GB'],
    ID: ['id-ID', 'en-GB'],
    IN: ['en-IN', 'en-GB'],
    MY: ['ms-MY', 'en-GB'],
    SG: ['en-SG', 'en-GB'],
    KR: ['ko-KR', 'en-GB'],
    TW: ['zh-TW', 'en-GB'],
    MX: ['es-MX', 'en-GB'],
    CA: ['en-CA', 'fr-CA', 'en-GB'],
    TH: ['th-TH', 'en-GB'],
    CN: ['zh-CN', 'en-GB'],
    VN: ['vi-VN', 'en-GB'],
    ZA: ['en-ZA', 'en-GB'],
    BR: ['pt-BR', 'en-GB'],
    JP: ['ja-JP', 'en-GB'],
    TR: ['tr-TR', 'en-GB'],
    CZ: ['cs-CZ', 'en-GB'],
    BA: ['en-GB'],
    HR: ['en-GB'],
    FI: ['en-GB'],
    LV: ['en-GB'],
    NO: ['en-GB'],
    RO: ['en-GB'],
    RS: ['en-GB'],
    SK: ['en-GB'],
    SI: ['en-GB'],
    UA: ['en-GB'],
    BG: ['en-GB'],
    AU: ['en-GB'],
    NZ: ['en-GB']
};
function getEnvironment() {
    const node = process.env.NODE_ENV;
    const lca = process.env.LCA_ENV;
    return node === 'development' ? 'development' : lca;
}
class DomainService {
    _domains;
    _domainCountryMap;
    constructor() {
        const env = getEnvironment();
        if (!exports.domains[env])
            throw new Error(`No domains found for environment ${env}`);
        this._domains = exports.domains[env];
        // perf: generate domain:country key value map so we don't have to use Object.keys/find
        this._domainCountryMap = Object.keys(this._domains).reduce((acc, key) => {
            acc[this._domains[key]] = key;
            return acc;
        }, {});
    }
    mapDomainToCountry(domain) {
        const countryCode = this._domainCountryMap[domain];
        return countryCode;
    }
    getLocalesForCountry(countryCode) {
        return exports.domainLocales[countryCode];
    }
    getAllDomains() {
        return Object.values(this._domains);
    }
    getDomainByRegion(region) {
        return this._domains[region];
    }
    // TODO: Caution: This will not work once we switched back to language codes instead of using full locales that include regions
    getRegionByLocale(locale) {
        return locale.split('-')[1] || 'GB';
    }
    getDomainByLocale(locale) {
        // If the locale starts with 'en-GB', return 'rbtx.co.uk'
        if (locale.startsWith('en-GB')) {
            return this._domains.GB;
        }
        // Otherwise, find the corresponding domain based on the locale
        for (const countryCode in exports.domainLocales) {
            if (exports.domainLocales[countryCode].includes(locale)) {
                return this._domains[countryCode];
            }
        }
        return undefined;
    }
    getApiDomainByRegion(region) {
        // TODO: This is a temporary fix for the preflight preview environment issue
        if (process.env.LCA_API_URL?.includes('.preflig.ht')) {
            const domain = process.env.LCA_API_URL.split('//')[1];
            return domain;
        }
        return `api.${this.getDomainByRegion(region)}`;
    }
    isSubdomainLocale(hostName) {
        if (hostName == this._domains.US)
            return false;
        if (process.env.NODE_ENV === 'production') {
            if (hostName.includes('.rbtx.'))
                return true;
            else
                return false;
        }
        return true;
    }
    getRootDomain() {
        return this._domains.US;
    }
    getLocaleByLangAndCountry(country, lang) {
        const baseLocale = `${lang.toLowerCase()}-${country}`;
        if (exports.domainLocales[country].includes(baseLocale))
            return baseLocale;
        return exports.domainLocales[country].find((loc) => loc.startsWith(lang)) || 'en-GB'; // TODO en-GB is not a good fallback, cause it does not exist in countries, like sweden
    }
}
const instance = new DomainService();
exports.DomainService = instance;
