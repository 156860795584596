import Link from 'next/link';
import { useRouter } from 'next/router';
import { twMerge } from 'tailwind-merge';
import { useLanguageSwitcher } from '../language-switcher/language-switcher';
import { CountryCode, DomainService } from 'shared';
import { useCountryCode } from '~/lib/hooks/useCountryCode';
import { useMemo, useState } from 'react';
import classNames from 'classnames';
import { buildAssetUrl } from '~/lib/assetPathHelper';
import Image from 'next/image';
import { ChevronDown, ChevronUp } from 'react-feather';

const localeMap = {
  en: 'English',
  de: 'Deutsch',
  fr: 'Français',
  es: 'Español',
  it: 'Italiano',
  pt: 'Português',
  ko: '한국어',
  zh: '中文',
  vi: 'Tiếng Việt',
  th: 'ไทย',
  ja: '日本語',
  id: 'Bahasa Indonesia',
  da: 'Dansk',
  pl: 'Polski',
  tr: 'Türkçe',
  // this are special cases because we use english for malaysia & estonia but there is neither en-MY nor en-EE
  cs: 'English',
  ms: 'English',
  et: 'English',
  nl: 'Nederlands',
  sv: 'English', // TODO(ROPLA-2781): rename to Svenska once we have translations
  bs: 'English',
  hr: 'English',
  fi: 'English',
  lv: 'English',
  nb: 'English',
  ro: 'English',
  sr: 'English',
  sk: 'English',
  sl: 'English',
  uk: 'English',
  bg: 'English'
};

type RegionProps = {
  region: Region;
  countries: Array<{
    name: string;
    countryCode: CountryCode;
    locales: Array<string>;
    hidden?: Array<CountryCode>;
  }>;
};

enum Region {
  Europe = 'Europe',
  Asia = 'Asia',
  America = 'America',
  Africa = 'Africa',
  Oceania = 'Oceania'
}

const entries: Array<RegionProps> = [
  {
    region: Region.Europe,
    countries: [
      {
        name: 'Germany',
        countryCode: 'DE',
        locales: ['de-DE', 'en-GB']
      },
      {
        name: 'United Kingdom',
        countryCode: 'GB',
        locales: ['en-GB']
      },
      {
        name: 'Austria',
        countryCode: 'AT',
        locales: ['de-AT', 'en-GB']
      },
      {
        name: 'France',
        countryCode: 'FR',
        locales: ['fr-FR', 'en-GB']
      },
      {
        name: 'Switzerland',
        countryCode: 'CH',
        locales: ['de-CH', 'fr-CH', 'it-CH', 'en-GB']
      },
      {
        name: 'Italy',
        countryCode: 'IT',
        locales: ['it-IT', 'en-GB']
      },
      {
        name: 'Poland',
        countryCode: 'PL',
        locales: ['pl-PL', 'en-GB']
      },
      {
        name: 'Denmark',
        countryCode: 'DK',
        locales: ['en-GB']
      },
      {
        name: 'Spain',
        countryCode: 'ES',
        locales: ['es-ES', 'en-GB']
      },
      {
        name: 'Turkey',
        countryCode: 'TR',
        locales: ['tr-TR', 'en-GB']
      },
      {
        name: 'Estonia',
        countryCode: 'EE',
        locales: ['en-GB']
      },
      {
        name: 'Portugal',
        countryCode: 'PT',
        locales: ['pt-PT', 'en-GB']
      },
      {
        name: 'Netherlands',
        countryCode: 'NL',
        locales: ['nl-NL', 'en-GB']
      },
      {
        name: 'Belgium',
        countryCode: 'BE',
        locales: ['nl-BE', 'fr-BE', 'en-GB']
      },
      {
        name: 'Sweden',
        countryCode: 'SE',
        locales: ['sv-SE']
      },
      {
        name: 'Czech Republic',
        countryCode: 'CZ',
        locales: ['cs-CZ']
      },
      {
        name: 'Bosnia and Herzegovina',
        countryCode: 'BA',
        locales: ['en-GB']
      },
      {
        name: 'Croatia',
        countryCode: 'HR',
        locales: ['en-GB']
      },
      {
        name: 'Finland',
        countryCode: 'FI',
        locales: ['en-GB']
      },
      {
        name: 'Latvia',
        countryCode: 'LV',
        locales: ['en-GB']
      },
      {
        name: 'Norway',
        countryCode: 'NO',
        locales: ['en-GB']
      },
      {
        name: 'Romania',
        countryCode: 'RO',
        locales: ['en-GB']
      },
      {
        name: 'Serbia',
        countryCode: 'RS',
        locales: ['en-GB']
      },
      {
        name: 'Slovakia',
        countryCode: 'SK',
        locales: ['en-GB']
      },
      {
        name: 'Slovenia',
        countryCode: 'SI',
        locales: ['en-GB']
      },
      {
        name: 'Ukraine',
        countryCode: 'UA',
        locales: ['en-GB']
      },
      {
        name: 'Bulgaria',
        countryCode: 'BG',
        locales: ['en-GB']
      }
    ]
  },
  {
    region: Region.Asia,
    countries: [
      {
        name: 'China',
        countryCode: 'CN',
        locales: ['zh-CN', 'en-GB']
      },
      {
        name: 'Taiwan',
        countryCode: 'TW',
        locales: ['zh-TW', 'en-GB'],
        hidden: ['CN']
      },
      {
        name: 'Singapore',
        countryCode: 'SG',
        locales: ['en-SG']
      },
      {
        name: 'India',
        countryCode: 'IN',
        locales: ['en-IN']
      },
      {
        name: 'South Korea',
        countryCode: 'KR',
        locales: ['ko-KR', 'en-GB']
      },
      {
        name: 'Vietnam',
        countryCode: 'VN',
        locales: ['vi-VN', 'en-GB']
      },
      {
        name: 'Thailand',
        countryCode: 'TH',
        locales: ['th-TH', 'en-GB']
      },
      {
        name: 'Japan',
        countryCode: 'JP',
        locales: ['ja-JP', 'en-GB']
      },
      {
        name: 'Indonesia',
        countryCode: 'ID',
        locales: ['id-ID', 'en-GB']
      },
      {
        name: 'Malaysia',
        countryCode: 'MY',
        locales: ['en-GB']
      }
    ]
  },
  {
    region: Region.America,
    countries: [
      {
        name: 'USA',
        countryCode: 'US',
        locales: ['en-US']
      },
      {
        name: 'Canada',
        countryCode: 'CA',
        locales: ['en-CA', 'fr-CA']
      },
      {
        name: 'Mexico',
        countryCode: 'MX',
        locales: ['es-MX', 'en-GB']
      },
      {
        name: 'Brazil',
        countryCode: 'BR',
        locales: ['pt-BR', 'en-GB']
      }
    ]
  },
  {
    region: Region.Africa,
    countries: [
      {
        name: 'South Africa',
        countryCode: 'ZA',
        locales: ['en-ZA']
      }
    ]
  },
  {
    region: Region.Oceania,
    countries: [
      {
        name: 'Australia',
        countryCode: 'AU',
        locales: ['en-GB']
      },
      {
        name: 'New Zealand',
        countryCode: 'NZ',
        locales: ['en-GB']
      }
    ]
  }
];

const CookieLink = ({
  locale,
  country,
  children
}: {
  locale: string;
  country: CountryCode;
  children: React.ReactNode;
}) => {
  const router = useRouter();
  const { setIsOpen } = useLanguageSwitcher();

  const domain = DomainService.getDomainByRegion(country);
  const url = `https://${domain}/${locale}/${router.asPath}`;

  const currentCountry = useCountryCode();

  const selected = router.locale === locale && country === currentCountry;

  return (
    <Link
      className={selected ? 'font-bold' : 'hover:font-bold'}
      href={router}
      onClick={async (e) => {
        e.preventDefault();

        if (router.pathname == '/select-locale') {
          await router.push('/', undefined, { locale });
        } else {
          await router.push(url, undefined, { locale });
          setIsOpen(false);
        }
      }}
    >
      {children}
    </Link>
  );
};

const CountryList = ({ countries }: { countries: RegionProps['countries'] }) => {
  const localeName = (locale: string) => {
    const [lang] = locale.split('-');
    const name = localeMap[lang as keyof typeof localeMap];

    return name || locale;
  };

  const currentCountry = useCountryCode();

  if (!currentCountry) {
    return <></>;
  }

  return (
    <>
      {countries
        .sort((a, b) => a.name.localeCompare(b.name))
        .filter((country) => !country.hidden?.includes(currentCountry))
        .map((country) => {
          const selected = country.countryCode === currentCountry;

          return (
            <div
              className={twMerge('mt-4 p-2 rounded-md flex gap-x-2', selected && 'bg-[#f3f4f6]')}
              key={country.name}
            >
              <div>
                <Image
                  className="rounded-sm min-w-[28px]"
                  src={buildAssetUrl(`/images/country-flags/${country.countryCode}.svg`)}
                  alt={`${country.name}`}
                  width={28}
                  height={20}
                />
              </div>
              <div className="flex flex-wrap md:block gap-x-2">
                <div className={twMerge('text-base text-black leading-[20px]', selected && 'font-bold')}>
                  {country.name}
                </div>
                <div className="flex gap-x-2 md:mt-1">
                  {country.locales.map((locale) => {
                    return (
                      <div className={'text-sm text-brand-darkPurple'} key={locale}>
                        <CookieLink country={country.countryCode} locale={locale}>
                          {localeName(locale)}
                        </CookieLink>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
};

type ViewProps = {
  region: Region;
  countries: Array<any>;
  setRegion: (region: Region) => void;
};

const DesktopView = ({ region, countries, setRegion }: ViewProps) => {
  return (
    <div>
      <div className="flex justify-center mt-3 md:mt-10 border-b-[1px] border-gray-[#F0F0F0]">
        {entries.map((entry) => {
          return (
            <div key={entry.region} className="relative py-2 px-2 md:px-4">
              <button className={'font-inter md:text-lg'} onClick={() => setRegion(entry.region)}>
                {entry.region.toUpperCase()}
              </button>
              <div
                className={classNames(
                  'absolute bottom-0 left-0 h-0.5 bg-brand-purple w-full',
                  entry.region !== region && 'hidden'
                )}
              />
            </div>
          );
        })}
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 md:mt-5 md:gap-y-10 w-full p-5 md:p-10">
        <CountryList countries={countries as RegionProps['countries']} />
      </div>
    </div>
  );
};

const MobileView = ({ region, countries, setRegion }: ViewProps) => {
  return (
    <div>
      <div className="flex flex-col justify-center mt-3 md:mt-10 border-b-[1px] border-gray-[#F0F0F0] divide-y">
        <div />
        {entries.map((entry) => {
          return (
            <div key={entry.region} className="relative py-2" onClick={() => setRegion(entry.region)}>
              <button
                className={
                  'font-inter font-medium text-xl md:text-lg text-[#2D3748] flex justify-between w-full px-5'
                }
              >
                {entry.region}
                <span>{region === entry.region ? <ChevronDown /> : <ChevronUp />}</span>
              </button>
              <div className={classNames(entry.region !== region && 'hidden', 'w-full px-3')}>
                <CountryList countries={countries as RegionProps['countries']} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const LocaleSelector = () => {
  const [region, setRegion] = useState(Region.Europe);

  const countries = useMemo(() => {
    const entry = entries.find((x) => x.region === region);

    return entry?.countries;
  }, [region]);

  if (!countries) {
    return null;
  }

  return (
    <div className="pt-6">
      <div className="text-xl md:text-2xl font-medium text-black mx-7 md:text-center -mt-2">
        Select your shopping region
      </div>
      <div className="md:hidden">
        <MobileView countries={countries} region={region} setRegion={setRegion} />
      </div>
      <div className="hidden md:block">
        <DesktopView countries={countries} region={region} setRegion={setRegion} />
      </div>
    </div>
  );
};

export default LocaleSelector;
