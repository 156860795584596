import { Dialog, Transition } from '@headlessui/react';
import LocaleSelector from '../locale-selector';
import { Fragment } from 'react';
import { X } from 'react-feather';

type Props = {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
};

const LanguageSwitcherModal = ({ isOpen, onClose }: Props) => {
  return (
    <Transition
      appear={true}
      show={isOpen}
      as={Fragment}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      <Dialog open={isOpen} onClose={onClose} className="absolute w-screen inset-0 z-[1200]">
        {/* Backdrop */}
        <div className="fixed inset-0 bg-black/70" aria-hidden="true" />

        {/* Full-screen scrollable container */}
        <div className="fixed inset-0 p-3 overflow-y-auto">
          {/* The actual dialog panel  */}
          <div className="flex items-center justify-center xl:mt-20 md:min-h-0">
            <Dialog.Panel
              as="div"
              className="relative w-full max-w-2xl lg:max-w-6xl rounded-lg bg-white shadow-md md:min-h-0 overflow-y-scroll md:overflow-y-auto"
            >
              <button className="bg-transparent absolute right-4 top-4 border-0" onClick={onClose}>
                <X />
              </button>
              <LocaleSelector />
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default LanguageSwitcherModal;
