import classNames from 'classnames';
import { Globe } from 'react-feather';
import { Fragment } from 'react';
import { Transition } from '@headlessui/react';
import LanguageSwitcherModal from '../language-switcher-modal';
import create from 'zustand';
import { useCountryCode } from '~/lib/hooks/useCountryCode';
import Skeleton from 'react-loading-skeleton';

type Props = {
  className?: string;
};

export const useLanguageSwitcher = create<{
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}>((set) => ({
  isOpen: false,
  setIsOpen: (isOpen) => set({ isOpen })
}));

const LanguageSwitcher = ({ className }: Props) => {
  // TODO: Tracking

  const { isOpen, setIsOpen } = useLanguageSwitcher();

  const countryCode = useCountryCode();

  return (
    <div
      data-testid="language-switcher-menu"
      className={classNames(className, 'relative text-left h-[25px]')}
    >
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={'uppercase inline-flex border-none bg-transparent text-base font-medium text-inherit'}
      >
        <Globe className="md:mr-1" aria-hidden="true" size={24} />
        <span className="uppercase">
          {countryCode ? <>{countryCode}</> : <Skeleton width={24} height={16} />}
        </span>
      </button>

      <Transition
        show={isOpen}
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white outline-brand-darkPurple outline-none outline-1">
          <div className="py-1" data-testid="language-switcher-container">
            <LanguageSwitcherModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default LanguageSwitcher;
