import { useEffect, useState } from 'react';
import { DomainService, CountryCode } from 'shared';

/**
 * A custom hook that retrieves the country code based on the current window location.
 * It uses the DomainService to map the domain of the current URL to a country code.
 * If the window location is not available (e.g., during server-side rendering or just not ready yet),
 * the hook will return null.
 *
 * @returns {CountryCode | null} The country code associated with the current domain,
 * or null if the window location is not available.
 */
export const useCountryCode = (): CountryCode | null => {
  const origin = typeof window !== 'undefined' && window.location.origin;

  const [countryCode, setCountryCode] = useState<CountryCode | null>(null);

  useEffect(() => {
    if (!origin) {
      return;
    }

    const url = origin && new URL(origin);
    const countryCode = url && DomainService.mapDomainToCountry(url.hostname);

    if (countryCode) {
      setCountryCode(countryCode);
    }
  }, [origin]);

  return countryCode;
};
