"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RBTX_COUNTRIES = exports.RBTX_LOCALES = void 0;
exports.RBTX_LOCALES = [
    // first language -> fallback
    'en-GB',
    'en-US',
    'de-DE',
    'fr-FR',
    'de-AT',
    'en-SG',
    'en-IN',
    'en-CA',
    'fr-CA',
    'it-IT',
    'ko-KR',
    'de-CH',
    'fr-CH',
    'it-CH',
    'pl-PL',
    'da-DK',
    'zh-CN',
    'vi-VN',
    'th-TH',
    'ja-JP',
    'zh-TW',
    'id-ID',
    'ms-MY',
    'es-ES',
    'en-ZA',
    'tr-TR',
    'pt-BR',
    'et-EE',
    'es-MX',
    'pt-PT',
    'nl-BE',
    'fr-BE',
    'nl-NL',
    'sv-SE',
    'cs-CZ'
];
exports.RBTX_COUNTRIES = []; // TODO
